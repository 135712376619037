<template>
    <div>
        <div class="mt-3 pt-3" v-if="userRole == 5 || (userRole == 6 && userHours == 1)"> 

            <b-row>
                <b-col cols="12">

                    <b-card>
                        <b-overlay :show="loading_list" :opacity="0.6">

                            <b-row class="app-card-header app-local-header-height">
                                <b-col start>
                                    <div class="app-card-tittle app-local-title-height d-flex align-items-center">                       
                                        <div>{{trans('h-declaration', 305)}} <span v-if="!loading_list">({{ items.total }})</span></div>
                                    </div>
                                </b-col>

                                <b-col cols="auto" class="app-local-big pl-0" end>
                                    <b-form-input type="text" v-model="filters.search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': filters.search.length > 0}" :placeholder="trans('search',280)" size="sm" />    
                                </b-col>

                                <b-col cols="auto" class="pl-0 app-local-big" end>
                                    <app-date-week-selector :choosedDate.sync="filters.offer_date" :listType.sync="filters.date_type" :changeArrows="true" :weekDates.sync="filters.offer_week" :key="refresh" :clearable="true" :onlyWeek="false"/>
                                </b-col>

                                <b-col cols="auto" end class="pl-0 app-local-big">
                                    <b-button-group  size="sm">
                                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.type !== 1, 'app-list-button-selected':  filters.type === 1 }" @click="filters.type = 1">{{trans('h-no-deviation', 305)}}</b-btn>
                                        <b-btn class="app-input-min-height app-list-button ml-n1" v-bind:class="{ 'btn-secondary-grey': filters.type !== 2, 'app-list-button-selected':  filters.type === 2 }" @click="filters.type = 2">{{trans('h-deviant', 305)}}</b-btn>
                                        <b-btn class="app-input-min-height app-list-button ml-n1" v-bind:class="{ 'btn-secondary-grey': filters.type !== 4, 'app-list-button-selected':  filters.type === 4 }" @click="filters.type = 4">{{trans('h-to-confirm', 305)}}</b-btn>
                                        <b-btn class="app-input-min-height app-list-button ml-n1" v-bind:class="{ 'btn-secondary-grey': filters.type !== 3, 'app-list-button-selected':  filters.type === 3 }" @click="filters.type = 3">{{trans('sh-all', 292)}}</b-btn>
                                    </b-button-group>                             
                                </b-col>

                                <b-col cols="auto" class="pl-0 d-flex align-items-center">   
                                    <div>
                                        <app-refresh :refresh.sync="refresh" :loading.sync="loading_list"/>
                                    </div>
                                </b-col>
                            </b-row>

                            <app-view-table :isTopBar="false" :filters="filters" :items="items" :fields="fields" :customColumns="custom_columns" :customColumnsHeaders="custom_headers" :isDropdownMenu="false" resizeableColumns resizeMinWidth>
                                
                                <template v-slot:head_selected>
                                    <app-check-box class="pb-2 pl-2" v-model="selected_all_rows" @change="selectAll"/>
                                </template>

                                <template v-slot:selected="cell">
                                    <app-check-box class="pl-2" v-model="cell.data.item.selected" @change="select($event)" />
                                </template>

                                <template v-slot:person_informations="cell">
                                    {{ cell.data.item.person_name  }} <br/>
                                    {{ cell.data.item.applicant_function_name }} <b-icon icon="info-circle-fill" :id="'popover-target-applicant_function'+cell.data.item.id_hour_data_main" v-if="cell.data.item.applicant_function_id != null && cell.data.item.applicant_function_id != cell.data.item.person_id_function_data_main" class="ml-auto mb-1" style="min-width: 17px; min-height: 17px;" />

                                    <b-popover :target="'popover-target-applicant_function'+cell.data.item.id_hour_data_main" triggers="hover" no-fade placement="bottomright" v-if="cell.data.item.applicant_function_id != null && cell.data.item.applicant_function_id != cell.data.item.person_id_function_data_main">
                                        <template #title>
                                            {{trans('h-registered-funtion', 305)}}
                                        </template>
                                        {{trans('h-main-function-differs', 291)}} <b>{{ cell.data.item.person_function_name ? cell.data.item.person_function_name : '-'}}</b>
                                    </b-popover>
                                </template>

                                <template v-slot:department="cell">
                                    {{ cell.data.item.location_name  }} <br/>
                                    {{ cell.data.item.department_name }}
                                </template>

                                <template v-slot:shift_function_name="cell">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="app-local-daytime" :id="'popover-target_function_additional'+cell.data.item.id_hour_data_main">{{ cell.data.item.shift_function_name}}</div>
                                    </div>
                                    <b-popover :target="'popover-target_function_additional'+cell.data.item.id_hour_data_main" triggers="hover" no-fade placement="bottomright">
                                        <template #title>
                                            {{ trans('sh-additional-functions', 292) }}
                                        </template>
                                        {{ cell.data.item.function_additional_names ? cell.data.item.function_additional_names : trans('sh-no-additional-functions', 292) }}
                                    </b-popover>
                                </template>

                                <template v-slot:daytime="cell">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="app-local-daytime app-ellips">{{ cell.data.item.daytime}}</div> 
                                            <b-icon v-if="cell.data.item.id_shift_const_daytime == 6" icon="info-circle-fill"  :id="'popover-target-daytime'+cell.data.item.id_hour_data_main" class="app-cursor-pointer ml-auto" style="min-width: 17px; min-height: 17px;" />
                                    </div>
                                    <b-popover :target="'popover-target-daytime'+cell.data.item.id_hour_data_main" triggers="hover" no-fade placement="bottomright" v-if="cell.data.item.id_shift_const_daytime == 6">
                                        <template #title>
                                            {{trans('cp-sleep-shift', 305)}}
                                        </template>
                                        <offers-hours-sleep-shift-component :item="cell.data.item" />
                                    </b-popover>
                                </template>

                                <template v-slot:declaration="cell">
                                    <div class="d-flex align-items-center justify-content-between">
                                        {{ JSON.parse(cell.data.item.hours_json).total_declarations_value ? '€'+(JSON.parse(cell.data.item.hours_json).total_declarations_value).replace('.',',') : ''  }} <b-icon icon="info-circle-fill"  :id="'popover-target-'+cell.data.item.id_hour_data_main" class="app-cursor-pointer ml-auto" style="min-width: 17px; min-height: 17px;" />
                                    </div>
                                    <b-popover :target="'popover-target-'+cell.data.item.id_hour_data_main" triggers="hover" no-fade placement="bottomright">
                                        <template #title>
                                            {{trans('h-declarations', 305)}}
                                        </template>
                                        <offers-hours-info-component :item="cell.data.item" />
                                    </b-popover>
                                </template>

                                <template v-slot:woon_km="cell">
                                    {{ cell.data.item.woon_km ? cell.data.item.woon_km +'KM' : ''  }}
                                </template>

                                <template v-slot:werk_km="cell">
                                    {{ cell.data.item.werk_km ? cell.data.item.werk_km +'KM' : ''  }}
                                </template>

                                <template v-slot:head_note="cell">

                                </template>

                                <template v-slot:note="cell">
                                    <div class="d-flex align-items-center justify-content-between mt-1">
                                        <b-icon 
                                            v-if="!cell.data.item.original_note" 
                                            icon="file-text" 
                                            class="app-cursor-pointer" 
                                            :id="'note-popover-'+cell.data.item.id_hour_data_main"
                                            style="min-width: 30px; min-height: 30px;"
                                        />
                                        <b-icon 
                                            v-else 
                                            icon="file-text-fill" 
                                            class="app-cursor-pointer" 
                                            :id="'note-popover-'+cell.data.item.id_hour_data_main"
                                            style="min-width: 30px; min-height: 30px;"
                                        />
                                    </div>
                                    <b-popover 
                                        :target="'note-popover-'+cell.data.item.id_hour_data_main"
                                        triggers="hover"
                                        placement="left"
                                    >
                                        <template #title>
                                            {{trans('c-note', 305)}}
                                        </template>
                                        <div class="note-popover-content">
                                            <app-memo v-model="cell.data.item.note" rows="5" :maxLength="500" class="mb-2"/>
                                            <div class="d-flex justify-content-end">
                                                <app-button
                                                    v-if="cell.data.item.note != cell.data.item.original_note" 
                                                    size="sm" 
                                                    type="secondary-grey" 
                                                    class="mr-2"
                                                    @click="cancelNoteEdit(cell.data.item)"
                                                >
                                                    {{trans('cancel', 282)}}
                                                </app-button>
                                                <app-button
                                                    v-if="cell.data.item.note != cell.data.item.original_note" 
                                                    size="sm" 
                                                    type="primary"
                                                    @click="saveNote(cell.data.item)"
                                                >
                                                    {{trans('save', 282)}}
                                                </app-button>
                                            </div>
                                        </div>
                                    </b-popover>
                                </template>

                                <template v-slot:fw_buttons="cell">
                                    <div class="d-flex justify-content-end" v-if="cell.data.item.id_hour_const_status == 2">
                                        <app-button type="primary" @click="confirmByClient(cell.data.item)">{{trans('h-approve', 305)}}</app-button>
                                        <app-button type="secondary-grey" @click="rejectByClient(cell.data.item)">{{trans('reject', 282)}}</app-button>
                                    </div>
                                    <div v-else>
                                        <span class="app-local-status ml-3">{{cell.data.item.status_name}}</span>
                                    </div>
                                </template>

                                <template v-slot:menu-items="cell">

                                </template>

                                <template v-slot:buttons>
                                    <app-button type="primary" :loading="loading_button" :disabled="items.total == 0 || loading_list" @click="confirmByClientAll()" v-if="filters.type == 1">{{trans('h-agree-all', 305)}}</app-button>

                                    <b-dropdown v-if=" checked_items > 0 && multi_menu_confirm_reject_by_client" no-caret toggle-class="app-button-multi mr-2" variant="secondary-grey">
                                        <template #button-content>
                                            {{trans('action',282)}} ({{checked_items}})                         
                                        </template>
                                        <b-dropdown-item v-if="multi_menu_confirm_reject_by_client" @click="confirmByClient(null)">{{trans('h-approve', 305)}}</b-dropdown-item>
                                        <b-dropdown-item v-if="multi_menu_confirm_reject_by_client" @click="rejectByClient(null)">{{trans('reject', 282)}}</b-dropdown-item>
                                    </b-dropdown>

                                    <app-button v-if="selected_all_rows == '1' || checked_items_export > 0" type="secondary-grey" @click="downloadList">{{ trans('h-export', 305) }} ({{ selected_all_rows == '1' ? items.total : checked_items_export }})</app-button>
                                </template>

                            </app-view-table>

                            <template #overlay>
                                <div></div>
                            </template>
                        </b-overlay>
                    </b-card>

                </b-col>
            </b-row>

        </div>
        <offers-hours-cancel-reason v-if="mCancelReason.show" :show.sync="mCancelReason.show" :args.sync="mCancelReason.args" :result.sync="mCancelReason.result" :return.sync="mCancelReason.return"/>

    </div>

</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';
import moment from "moment";
import OffersHoursInfoComponent from './OffersHoursInfoComponent.vue';
import OffersHoursCancelReason from './OffersHoursCancelReason.vue';
import OffersHoursSleepShiftComponent from './OffersHoursSleepShiftComponent.vue';

export default {
    components: {
       OffersHoursInfoComponent,
       OffersHoursCancelReason,
       OffersHoursSleepShiftComponent,
    },
    
    created(){
            this.userRole = JSON.parse(localStorage.getItem('user-portal')).id_user_const_role
            this.userHours = JSON.parse(localStorage.getItem('user-portal')).hours
    },  

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            multi_menu_confirm_reject_by_client: false,
            filters: {             
                page: 1,
                per_page: 10,
                search: "",                
                changed: 0,
                date_type: 1,
                offer_date: moment().format('DD-MM-YYYY'),
                offer_week: this.getCorrectWeek(),
                ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main).map(item => item.value),
                ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main).map(item => item.value),
                client_link: JSON.parse(localStorage.getItem('user-portal')).client_link,

                type: 1,
                filtering: {         
                    persons: {type: 'list', changed: 0, values: [], function: 'getHoursPersons', parameters: null, widthClass:'app-width-min-250'},  
                    functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},            
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},
                    locations: {type: 'list',  changed: 0, values: [], function: "getHoursLocations", parameters: null, widthClass:'app-width-min-250'},
                    daytime_name: { type: 'list', changed: 0, values: [], function: "getShiftDaytime", parameters: null, widthClass:'app-width-min-350'},
                },
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                }
            },

            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", thStyle: { "min-width": "30px", width: "30px" },visible: true  },
                { key: "person_informations", label: this.trans('flexworker', 280), thStyle: { "min-width": "210px", "width": "100%" }, visible: true, filtering: true, filtering_name: "persons"},
                { key: "department", label: this.trans('h-department', 305), thStyle: { "min-width": "155px", "width": "155px" }, visible: true, filtering: true, filtering: true, filtering_name: "locations"},
                { key: "shift_function_name", label: this.trans('sh-function',292), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "functions"},
                { key: "hour_date_from", label: this.trans('pl-date',288), thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: true, filtering_name: "dates"},
                { key: "plan_work_times", label: this.trans('h-planned-time', 305), thStyle: { "min-width": "135px", "max-width": "135px" }, visible: true},
                { key: "real_work_times", label: this.trans('h-real-time', 305), thStyle: { "min-width": "135px", "max-width": "135px" }, visible: true, filtering: false},
                { key: "daytime", label: this.trans('sh-type-of-service', 292), thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: true, filtering_name: 'daytime_name'},
                { key: "shift_code", label: this.trans('sh-shift-code', 292), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true},
                { key: "pause", label: this.trans('h-pause', 305), thStyle: { "min-width": "85px", "max-width": "85px" }, visible: true, filtering: false},
                { key: "real_work_hours", label: this.trans('h-total', 305), thStyle: { "min-width": "85px", "max-width": "85px"}, visible: true}, 
                { key: "woon_km", label: this.trans('h-home-work-km', 305), thStyle: { "min-width": "100px", "max-width": "100px"}, visible: true},             
                { key: "werk_km", label: this.trans('h-work-work-km', 305), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true},
                { key: "declaration", label: this.trans('h-declaration', 305), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true},
                { key: "note", label: '', thStyle: { "min-width": "50px", "max-width": "150px"}, tdClass: "app-local-top-align app-local-row-center", visible: true, filtering: false, sortable: false},

                { key: "fw_buttons", label: '', thStyle: { "min-width": "470px", "max-width": "470px" }, "tdClass" : "app-local-fw-buttons", visible: true},
            ],

            custom_columns: ['selected', 'person_informations', 'department', 'shift_function_name', 'declaration', 'woon_km', 'werk_km', 'fw_buttons', 'daytime', 'note'],
            custom_headers: ['selected', 'note'],
            selected_all_rows: '0',
            checked_items: 0,
            checked_items_export: 0,
            removeAll: false,
            items: [],
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            loading_list: false,
            loading_button:false,
            refresh: 0,
            sidebar_open: false,
            multiMenuVisible: true,
            confirmVisible: true,
            rejectVisible: true,
            cancelVisible: true,
            mCancelReason: { show: false,
                args: {
                    client_description: '',
                    ids_hour_data_main: [],
                    person_name: null,
                    id_cancel_const_reason: null
                }, result: 0, return: null
            },
            client_link: JSON.parse(localStorage.getItem('user-portal')).client_link,
            ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main).map(item => item.value),
            ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main).map(item => item.value),

        };
    },
    
    methods: {

        getCorrectWeek() {

            const date = moment();

            const isoYear = date.isoWeekYear();
            const isoWeek = date.isoWeek();

            return `${isoYear}-${String(isoWeek)}`;
        },

        getList() {

            this.loading_list = true;
            axios
                .post("hours/getList", {
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {      
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                });
        },  

        confirmByClient(item){
            let ids_hour_data_main = []
            if(item){
                ids_hour_data_main[0] = item.id_hour_data_main
            }else{
                this.items.list.forEach(element => {
                    if(element.selected == true){
                        ids_hour_data_main.push(element.id_hour_data_main)
                    }
                });
            }

            this.alertClass.openAlertConfirmation(this.trans('a-are-you-sure', 291)).then(res => { 

                if(res == true){

                    this.loading_list = true;
                    axios
                        .post("hours/confirmByClient", {
                            filters: JSON.stringify(this.filters),
                            ids_hour_data_main: JSON.stringify(ids_hour_data_main),
                        })
                        .then((res) => {
                            this.items = res.data.items;
                            this.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                            this.checked_items = 0;
                            this.checked_items_export = 0;
                            this.selected_all_rows = '0';
                        });

                    
                }

            });
        },

        confirmByClientAll(){

            this.alertClass.openAlertConfirmation(this.trans('a-are-you-sure', 291)).then(res => { 

                if(res == true){

                    this.loading_button = true;
                    axios
                        .post("hours/confirmByClientAll", {
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.items = res.data.items;
                            this.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_button = false;
                            this.checked_items = 0;
                            this.checked_items_export = 0;
                            this.selected_all_rows = '0';
                        });

                    
                }

            });
        },

        rejectByClient(item){
            let ids_hour_data_main = []
            if(item){
                ids_hour_data_main[0] = item.id_hour_data_main
                this.mCancelReason.args.person_name = item.person_name;
            }else{
                this.items.list.forEach(element => {
                    if(element.selected == true){
                        ids_hour_data_main.push(element.id_hour_data_main)
                    }
                });
                this.mCancelReason.args.person_name = null;
            }

            this.mCancelReason.args.id_cancel_const_reason = null;
            this.mCancelReason.args.ids_hour_data_main = ids_hour_data_main;
            this.mCancelReason.args.client_description = '';
            this.mCancelReason.show = true;
            
        },

        reject(){
            
            let ids_hour_data_main = this.mCancelReason.args.ids_hour_data_main;
            this.loading_list = true;
            
            axios
                .post("hours/rejectByClient", {
                    filters: JSON.stringify(this.filters),
                    ids_hour_data_main: JSON.stringify(ids_hour_data_main),
                    client_description: this.mCancelReason.args.client_description,
                    id_cancel_const_reason: this.mCancelReason.args.id_cancel_const_reason
                })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.mCancelReason.args.ids_hour_data_main = [];
                });

                    
                

           
        },

        select(value){

            var selected_items = 0;

            selected_items = this.items.list.filter((item) => {return item.selected == '1'}).length;

            this.checked_items = selected_items
            this.checked_items_export = this.items.list.filter((item) => {return item.selected == '1'}).length;

            this.isMultiMenuVisible();
        },

        selectAll(value){
            if(value == "1"){
                this.items.list.forEach(item => {
                    item.selected = "1";
                });

                this.checked_items = this.items.list.length;
                this.checked_items_export = this.items.list.length;
            } else {
                this.items.list.forEach(item => {
                    item.selected = "0";
                });

                this.checked_items = 0;
                this.checked_items_export = 0;
            }

            this.isMultiMenuVisible();
        },

        isMultiMenuVisible(){

            if (this.items != undefined && this.items.list != undefined) {

                let selected_to_confirm_reject_by_client = this.items.list.filter(function(value) { 
                    return value.selected == true && [2].includes(value.id_hour_const_status); 
                }).length;

                this.multi_menu_confirm_reject_by_client = selected_to_confirm_reject_by_client == this.checked_items ? true : false;

            }else{
                this.multi_menu_confirm_reject_by_client = false;
            }

        },

        setLocation(){
            if(!this.client_link){
                if(this.ids_location_data_main.length > 0){
                    this.filters.ids_location_data_main = this.ids_location_data_main.map(item => item.value);
                }
            } else {
                this.filters.ids_location_data_main = []; 
            }
        },

        cancelNoteEdit(item) {
            item.note = item.original_note;
        },

        saveNote(item) {
            this.loading_list = true;
            axios
                .post("hours/changeNote", {
                    id_hour_data_main: item.id_hour_data_main,
                    note: item.note,
                    filters: JSON.stringify(this.filters),
                })
                .then(() => {
                    this.getList();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 27);
        },

        async loadSettings() {
            var sFilters = await this.userSettings.checkSettings(27, this.filters, 'portalDeclarationList');

            this.filters.ids_location_data_main = this.ids_location_data_main;
            this.filters.ids_department_data_main = this.ids_department_data_main;
            this.filters.client_link = this.client_link;
            // this.setLocation();

            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting;
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10; 
            this.filters.page = sFilters.page != undefined ? sFilters.page : 1;    
            this.filters.type = sFilters.type; 
            this.filters.date_type = sFilters.date_type;
            this.filters.offer_date = sFilters.offer_date == null ?  moment().format('DD-MM-YYYY') : sFilters.offer_date;
            this.filters.offer_week = sFilters.offer_week == null ? moment().format('YYYY-W') : sFilters.offer_week;

            if(this.$route.params.from_dashboard && this.$route.params.from_dashboard == 1){
                this.filters.type = 1;
            }else if(this.$route.params.from_dashboard && this.$route.params.from_dashboard == 2){
                this.filters.type = 3;
            }

            
            await this.$nextTick();
            
        },

        async onCreateThis() { 
            
            await this.loadSettings();
            this.getList();
        
            this.$watch('filters.offer_date', function(newVal, oldVal) {
                if (!this.loading_list && this.filters.date_type == 1) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';                     
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.items = [];
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.offer_week', function(newVal, oldVal) {
                if (!this.loading_list && this.filters.date_type == 2) { 
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';      
                    this.getList();
                    this.saveSettings();
                } 
            });

        
            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                } 
            });
          
            this.$watch('filters.date_type', function(newVal, oldVal) {
                if (!this.loading_list) { 
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                } 
            });

            
            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                } 
            });     
           
            this.$watch('filters.page', function(newVal, oldVal) {
                this.cheked_items = 0;
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings()
                }   
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) { 
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.dates.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.persons.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.daytime_name.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.checked_items_export = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('mCancelReason.result', function(newVal, oldVal) {
                if(this.mCancelReason.result == 1){
                    this.reject();

                    this.mCancelReason.result = 0;
                }
            });

        },

        downloadList(){
            this.loading_list = true;

            var selected_items = [];

            if (this.checked_items_export != 0 && this.selected_all_rows != '1') {
                selected_items = this.items.list.filter(item => item.selected == true).map(item => item.id_hour_data_main);
            }

            axios
                .post("hours/downloadList", {
                    filters: JSON.stringify(this.filters),
                    selected_items: JSON.stringify(selected_items),
                    selected_all_rows: this.selected_all_rows,
                },
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {                 
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Declaraties ' + moment().format('DD-MM-YYYY - HHmmss') +'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },
        
       
    },
};
</script>

<style scoped>
.app-local-fw-buttons{
    max-width: 230px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.app-local-status{
    margin-right: 10px;
    display: inline-block; 
    width: 100px;          
    white-space: nowrap; 
}
.app-local-note{
    margin-left: 13px;
    margin-top: 12px;
}
.custom-popover-local-notes {
    overflow-y: auto !important;
    max-height: 500px !important;
}
.note-popover-content {
    min-width: 300px;
}
</style>
