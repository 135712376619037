<template>
    <div>
        <div v-for="item in declarations" :key="item.id_placement_const_component">
            <div class="d-flex justify-content-between mt-2" v-if="![9].includes(item.id_placement_const_component)">
                <b>{{item.name}}</b>
                <b v-if="![3,4].includes(item.id_placement_const_component)">€{{parseFloat(item.amount).toFixed(2).replace('.',',')}}</b>
                <b v-if="[3, 4].includes(item.id_placement_const_component)">{{getDeclarationValue(item)}}</b>
            </div>
            <b-alert show class="mt-1" v-if="![9].includes(item.id_placement_const_component)">
                <div v-if="item.description != null && item.description != ''">{{item.description}}<br /></div>
                <div v-if="item.explanation != null && item.explanation != ''">{{item.explanation}}</div>
                <div v-if="(item.description == null || item.description == '') && (item.explanation == null || item.explanation == '')">-</div>
            </b-alert>
        </div>

        
        <div>
            <b>{{ trans('h-fw-explanation', 305)}}</b>
                <b-alert show class="mt-1">
                    {{ (item.person_description ? item.person_description: '-') }}
                </b-alert>
            <b>{{ trans('h-client-explanation', 305)}}</b>
            <b-alert show class="mt-1">
                    {{ (item.client_description ? item.client_description: '-') }}
            </b-alert>
        </div>

        <div class="d-flex justify-content-end">
            <h4><b>{{trans('h-total', 305)}}: €{{(hours_json.total_declarations_value != null ? hours_json.total_declarations_value.replace('.',',') : '0,00')}}</b></h4>
        </div>
    </div>
</template>
<script>
export default {
    props: ["item"],

    created(){
        this.hours_json = JSON.parse(this.item.hours_json);
        this.declarations = JSON.parse(this.item.declarations);
        this.placement_parameters = JSON.parse(this.item.placement_parameters);
    },

    data(){
        return{
            hours_json: null,
            declarations: null,
            placement_parameters: null
        }
    },
    methods: {
        getDeclarationValue(declaration){
            var value = 0;
            if(this.placement_parameters.informations.client_contract_km_scale == 1){
                
                declaration.parameters.forEach(item_param => {

                    if(item_param.people == 1 && item_param.type == 0 && declaration.distance >= parseInt(item_param.km_from) && (declaration.distance <= parseInt(item_param.km_to) || item_param.km_to == null)){
                        value = parseFloat(value) + parseFloat(item_param.person_value.toString().replace(',', '.'));  
                    }else if(item_param.people == 1 && item_param.type == 1 && declaration.distance >= parseInt(item_param.km_from) && (declaration.distance <= parseInt(item_param.km_to) || item_param.km_to == null)){
                        value = parseFloat(value) + parseFloat(item_param.person_value.toString().replace(',', '.') * declaration.distance);  
                    }
                });

            }else{
                value = parseFloat(value) + parseFloat(declaration.person_value * declaration.distance);    
            }

            if(value > 0){
                return "€ " + parseFloat(value).toFixed(2).toString().replace(".", ",");
            }else{
                return null;
            }
        }
    }
}
</script>
<style scoped>

</style>