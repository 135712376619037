<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('h-reject-declaration', 305)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent>

                <app-row-left-label :label="trans('h-cancel-reason', 305)">
                    <app-radio-group :disabled="loading" v-model="cArgs.id_cancel_const_reason" :options="cancel_reason_type" :validatorName="trans('h-cancel-reason', 305)" validatorRules="required" />
                </app-row-left-label>
                
                <app-row-left-label :label="trans('description', 280)" class="mt-2">
                        <app-memo v-model="cArgs.client_description" :maxLength="500" lettersCounter />
                </app-row-left-label>
                    
            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',282)}}</app-button>
            <app-button type="primary" @click="setReason" :loading="loading">
                {{trans('reject',282)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppRowLeftLabel from '../../../../@core/components/AppRowLeftLabel.vue';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
        ValidationObserver,
        AppRowLeftLabel
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

    },

    mounted(){
    
    },

    watch: {


    },

    data() {
        return {
            loading: false,
            alertClass: new AppAlerts(),
            cancel_reason_type: [
                                { value: 5, text: this.trans('h-cancel-reason-no-show', 305) },
                                { value: 6, text: this.trans('h-cancel-reason-sick', 305) },
                                { value: 4, text: this.trans('h-cancel-reason-other', 305) }
                                ]
        };
    },

    methods: {

        setReason(){

            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                } else {

                    this.alertClass.openAlertConfirmation(this.args.person_name == null ? (this.trans('a-are-you-sure', 291)) : (this.trans('hour-reject-alert', 291, {person_name: this.args.person_name}))).then(res => {

                        if(res == true){
                            this.$emit("update:result", 1);
                            this.computedShow = false;
                        }

                    });

                    
                }
            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>
