<template>
    <div>
        <b-row class="my-1">
            <b-col v-if="item.is_sleep_shift_check == 0"><!-- Odliczamy 50% -->
                <div>
                    <span v-if="JSON.parse(item.sleep_shift_parameters).sleep_shift_different_compensation == 0">{{trans('cp-hours-counted', 305)}} </span> 
                    <span v-if="JSON.parse(item.sleep_shift_parameters).sleep_shift_different_compensation == 1">{{trans('cp-hours-not-changed', 305)}} </span>
                    {{ cTotalHalfPercentHours }} 
                </div>
                <div>
                    {{trans('cp-hours-counted-100', 305)}} {{ cTotalFullPercentHours }}
                </div>
            </b-col>

            <b-col v-if="item.is_sleep_shift_check == 1"><!-- FW został obudzony 3 razy liczymy wszsytko 100% -->
                {{trans('cp-hours-counted-100', 305)}} {{ cTotalFullPercentHours }}
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col cols="12">
                {{ trans('h-fw-if-wake-up', 305)}}: <span>{{ (item.is_sleep_shift_check == 1 ? 'Ja' : 'Nee') }}</span>
            </b-col>
            <b-col cols="12" class="mt-2">
                {{ trans('h-fw-description', 305)}}: {{ (item.sleep_shift_description ? item.sleep_shift_description: '-') }}
            </b-col>
        </b-row>

    </div>
</template>
<script>
export default {
    props: ["item"],

    created(){
    },

    computed:{
        cTotalFullPercentHours: {
            get(){
                let hours = JSON.parse(this.item.hours_json).hours;
                let sleep_shift_hours = JSON.parse(this.item.sleep_shift_hours_json);
                let sleep_shift_parameters = JSON.parse(this.item.sleep_shift_parameters);
                
                if(hours.length > 0 && sleep_shift_parameters != null){
                    
                    let total_hours = 0;
                    hours.forEach(item => {
                        if(item.type == 1){
                            total_hours = total_hours + item.hours;
                        }else if(item.type == 2){
                            total_hours = total_hours - item.hours;
                        }
                    });

                    if(this.item.is_sleep_shift_check == 1){
                        if(total_hours > 0){
                            total_hours = this.convertToTime(total_hours);
                            return total_hours + " " + this.trans('cp-hours',305);
                        }else{
                            return null;
                        }
                    }else{

                        if(sleep_shift_hours != null){

                            let total_sleep_hours = 0;
                            sleep_shift_hours.forEach(item => {
                                total_sleep_hours = total_sleep_hours + item.sleep_shift_hours;
                            });

                            if(sleep_shift_parameters.sleep_shift_different_compensation == 1){
                                total_sleep_hours = total_sleep_hours;
                            }else{
                                total_sleep_hours = total_sleep_hours * 2;
                            }
                            
                            if(total_sleep_hours > 0){
                                total_hours = this.convertToTime(total_hours - total_sleep_hours);
                                return total_hours + " " + this.trans('cp-hours',305);
                            }else{
                                return null;
                            }

                        }else{
                            return null;
                        }


                    }

                    
                }else{
                    return null;
                }
            }
        },

        cTotalHalfPercentHours: {
            get(){
                let hours = JSON.parse(this.item.hours_json).hours;
                let sleep_shift_parameters = JSON.parse(this.item.sleep_shift_parameters);
                let sleep_shift_hours = JSON.parse(this.item.sleep_shift_hours_json);

                if(hours.length > 0 && sleep_shift_hours != null){

                    let total_sleep_hours = 0;
                    sleep_shift_hours.forEach(item => {
                        total_sleep_hours = total_sleep_hours + item.sleep_shift_hours;
                    });

                    if(sleep_shift_parameters.sleep_shift_different_compensation == 1){
                        total_sleep_hours = total_sleep_hours;
                    }else{
                        total_sleep_hours = total_sleep_hours * 2;
                    }
                    

                    if(total_sleep_hours > 0){
                        total_sleep_hours = this.convertToTime(total_sleep_hours);
                        return total_sleep_hours + " " + this.trans('cp-hours',305);
                    }else{
                        return null;
                    }
                }else{
                    return null;
                }
            }
        }
    },

    data(){
        return{

        }
    },
    methods: {
        convertToTime(hour){
            let total_minutes = hour * 60;
            const hours = Math.floor(total_minutes / 60);
            const minutes = total_minutes % 60;

            return hours.toString().padStart(2, "0") + ":" + parseInt(Math.round(minutes)).toString().padStart(2, "0");
        },
    }
}
</script>
<style scoped>

</style>